import { Chip, FormControl, MenuItem, Select, Stack } from '@mui/material'
import { keyBy } from 'lodash'
import { useDeepCompareMemo } from 'use-deep-compare'

import { useNormalizedSortBy } from '@/utils/frontend/useNormalizedSortBy'
import { useT } from '@/utils/frontend/useT'

// Desktop version should be a dropdown with options
export const SortByDropdown = ({ dataCyPrefix }: { dataCyPrefix: string }) => {
  const { t } = useT({ keyPrefix: 'catalog.filters' })
  const { currentRefinement, options, refine, initialIndex, originalItems } =
    useNormalizedSortBy()

  const optionsMap = useDeepCompareMemo(() => keyBy(options, 'value'), [options])

  return (
    <FormControl variant="standard" data-cy={`${dataCyPrefix}-SortByDropdown`}>
      {/* Cannot directly add `aria-label` to HTML-native with MUI somehow, this is a workaround */}
      {/* eslint-disable jsx-a11y/label-has-associated-control */}
      <label id="sorting-label" style={{ display: 'none' }}>
        {t('sort-by-label')}
      </label>
      <Select
        variant="outlined"
        defaultValue={initialIndex}
        onChange={(e) => refine(e.target.value)}
        value={currentRefinement}
        renderValue={(value) => `${t('sort-by-label')}: ${optionsMap[value].label}`}
        inputProps={{
          'aria-label': t('sort-by-label'),
          'aria-labelledby': 'sorting-label',
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.value}
            value={option.value}
            data-cy={`${dataCyPrefix}-SortByDropdown-MenuItem-${originalItems[index].label}`}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

/**
 * @deprecated SortByList not used anymore
 */
export const SortByList = () => {
  const { currentRefinement, options, refine, originalItems } = useNormalizedSortBy()

  return (
    <Stack direction="row" data-cy="SortByList" spacing={1.5}>
      {options.map((option, index) => (
        <Chip
          key={option.value}
          label={option.label}
          color="secondary"
          variant={currentRefinement === option.value ? 'filled' : 'outlined'}
          onClick={() => refine(option.value)}
          data-cy={`SortByList-Chip-${originalItems[index].label}`}
        />
      ))}
    </Stack>
  )
}
